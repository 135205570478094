<template>
	<section class="background-div">
		<div class="card-container">
			<div>
				<div>
					<div class="card card-info">
						<div class="card-header text-center">
							<h4 class="m-0">Bem-vindo!</h4>
						</div>
						<div class="card-body">
							<b-form @submit.prevent="login">
								<div>
									<b-form-input
										id="user"
										v-model="usuario"
										class="form-control input-sm chat-input"
										placeholder="Usuário"
									/>
								</div>
								<br>
								<div>
									<b-form-input
										id="pass"
										v-model="senha"
										class="form-control input-sm chat-input"
										type="password"
										placeholder="Senha"
									/>
								</div>

								<br>
								<div class="text-center">
									<button
										type="submit"
										class="btn btn-primary w-100"
										:disabled="!usuario || !senha"
									>
										Entrar
									</button>
								</div>
							</b-form>
						</div>
					</div>
					<div class="login-msg" v-if="success || error">
						<span v-if="success" class="success-msg">
							Logado com sucesso! Redirecionando...
						</span>
						<span v-else-if="error" class="error-msg">
							{{ error }}
						</span>
						<br v-else>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
	section {
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card-container {
		width: 50%;
		max-width: 440px;
	}

	.login-msg {
		text-align: center;
		padding-top: 0.5rem;
	}

	.error-msg {
		color: red;
	}

	.success-msg {
		color: #008000;
	}

	.btn-primary {
		background-color: var(--cal-header-color);
		border-color: var(--cal-header-color);
	}

	.background-div {
		position: absolute;
		width: 100%;
		height: 100vh;  /* Ajusta según sea necesario */
		overflow: auto !important;
		background-image: url('../assets/img/background.png');
		background-size: cover;  /* Ajusta el tamaño de la imagen */
		background-position: center;  /* Centra la imagen */
		background-repeat: no-repeat;  /* Evita que la imagen se repita */
	}
</style>

<script>
	import { AuthService } from "@/services/auth";

	export default {
		data () {
			return {
				usuario: "",
				senha: "",
				error: "",
				success: false,
				authService: new AuthService,
				backgroundImageUrl: '../assets/img/background.png'
			};
		},

		methods: {
			async login () {
				try {
					if (!this.usuario || !this.senha) return;

					this.error = "";
					this.success = false;
					this.$store.commit("setUsuarioLogado", null);

					const response = await this.authService.login({ user: this.usuario, senha: this.senha });
					this.usuario = "";
					this.senha = null;
					if (!response.success) {
						throw new Error(response.error);
					} else {
						this.$store.commit("setUsuarioLogado", response.usuarioLogado);
						this.success = true;
						this.$router.replace(this.$route.params.next || "/");
					}
				} catch (error) {
					console.log(error);
					this.error = error.message || "Erro desconhecido, por favor tente novamente.";
				}
			}
		}
	};
</script>
